import { faAngleDown as lightAngleDown } from "@jewlr/pro-light-svg-icons/faAngleDown"
import { faAngleRight as lightAngleRight } from "@jewlr/pro-light-svg-icons/faAngleRight"
// import { faFlowerTulip } from "@jewlr/pro-light-svg-icons/faFlowerTulip"
import { faGift } from "@jewlr/pro-light-svg-icons/faGift"
import { faTimes as lightTimes } from "@jewlr/pro-light-svg-icons/faTimes"
// import { faBoxHeart } from "@jewlr/pro-regular-svg-icons"
import { Expand } from "@jewlr/storybook/animate"
import {
  Box,
  Button,
  Flex,
  FontAwesomeIcon,
  Typography,
} from "@jewlr/storybook/core"
import { Confirmation } from "@jewlr/storybook/modal"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { ItemAction as RemoveItem } from "areas/cart/components"
import { removeLineItem } from "areas/cart/store/actions"
import { openModal } from "components/modal"
import { isTestMode, IS_JEWLR, jewlr_t } from "helpers/application"
import { to_currency } from "helpers/number"

import {
  ItemAction,
  CardItemInfo,
  CardTitle,
  Card,
  CardRemove,
  CardProductImage,
  CardProductImageContainer,
  OutOfStockLabel,
} from "../components"
import GiftOptions from "../gift-wrap/gift-options"
import Price from "../jewlr/price"
import LineItemShipping from "../line-item-shipping"
import WarrantyAddon from "../warranty/warranty-addon"

import LineItemAddon from "./line-item-addon"

const ItemInfo = styled(CardItemInfo)`
  align-items: flex-end;
  margin-bottom: 0;
  margin-left: 8px;
  text-align: right;
`

const Detail = styled(Box)`
  font-size: 12px;
  letter-spacing: 0.5px;
  margin: 0 0 5px;
`

const SubDetail = styled(Typography).attrs((props) => ({
  fontSize: "12px",
  letterSpacing: "0.5px",
  mb: "4px",
  ...props.theme.cart.itemDetails,
}))``

const FlexItemAction = styled(Flex).attrs((props) => ({
  color: props.theme.colors.primary,
  justifyContent: "space-between",
  mb: "10px",
}))``

// const GuaranteedText = styled(Box).attrs({
//   as: "span",
// })`
//   color: ${(props) => props.theme.colors.guaranteed};
//   font-style: italic;
// `

const InternalLineItemMobile = ({ item, removeItem }) => {
  const [showDetails, toggleDetails] = useState(false)
  const [showRemoveDialog, toggleRemoveDialog] = useState(false)
  // const forHer = item.product_gender === "female"

  const outOfStock = !item.in_stock

  return (
    <>
      <Card>
        <CardRemove
          aria-label={`Remove ${item.title} from cart`}
          data-cy={`cart-remove-btn-mobile-${item.style_code}`}
          onClick={() => toggleRemoveDialog(true)}
        >
          <FontAwesomeIcon icon={lightTimes} />
        </CardRemove>
        <Confirmation
          cancelText="Cancel"
          confirmText="Yes"
          onCancel={() => toggleRemoveDialog(false)}
          onConfirm={() => {
            removeItem(item.id)
            toggleRemoveDialog(false)
          }}
          render={() => (
            <Typography
              fontSize={16}
              fontWeight={500}
              letterSpacing="-0.02em"
              lineHeight="20px"
              mb={5}
            >
              Are you sure you want to remove this <br />
              item from your {jewlr_t("Cart")}?
            </Typography>
          )}
          show={showRemoveDialog}
          title="Remove Item"
          variant="squircle"
        />

        <CardTitle as={Link} data-lc="line-items" to={item.modify_url}>
          {item.title}
        </CardTitle>
        {item.holiday_availability && !outOfStock && (
          <Flex alignItems="center" justifyContent="center" pb="2px" pt={2}>
            {/* <FontAwesomeIcon
              icon={faGift}
              style={{ verticalAlign: "text-top" }}
            /> */}
            <FontAwesomeIcon
              color="greenDarker"
              fontSize="16px"
              icon={faGift}
              mr={1}
            />
            <Typography
              color="greenDarker"
              fontSize="16px"
              fontWeight="600"
              letterSpacing="-0.03em"
            >
              {/* <Box
              as="img"
              height="16px"
              mr="1px"
              src={require("images/icons/doublehearts_bold.svg")}
              verticalAlign="text-top"
              width="auto"
            />{" "} */}
              Arrives Before{" "}
              {sl.config.guaranteed_holiday_alias ||
                sl.config.guaranteed_holiday}
              {/* Arrives{" "}
            {new Date(item.min_delivery_date).getDate() ===
            new Date(sl.config.guaranteed_receive_date).getDate()
              ? "By"
              : "Before"}{" "}
            {sl.config.guaranteed_holiday} */}
            </Typography>
          </Flex>
        )}
        <Flex
          alignItems="flex-start"
          justifyContent="space-between"
          padding="16px 0 16px"
        >
          <Link data-lc="line-items" to={item.modify_url}>
            <CardProductImageContainer outOfStock={outOfStock}>
              <CardProductImage
                alt={item.title}
                data-cy={`cart-line-item-img-mobile-${item.style_code}`}
                data-sku={item.sku}
                ratio="120 / 120"
                src={item.image_url}
              />
              {outOfStock && <OutOfStockLabel>Out Of Stock</OutOfStockLabel>}
            </CardProductImageContainer>
          </Link>
          <ItemInfo>
            {outOfStock ? (
              <RemoveItem
                data-cy={`cart-remove-btn-mobile-${item.style_code}`}
                fontWeight="500"
                onClick={() => toggleRemoveDialog(true)}
              >
                Remove from {jewlr_t("Cart")}
              </RemoveItem>
            ) : (
              <>
                <Price dataCyType="mobile" item={item} />
                <LineItemShipping item={item} />
                {!item.reject_addons && IS_JEWLR() && (
                  <Button
                    color="primary"
                    fontSize={12}
                    onClick={() => {
                      openModal(`#gift-preview-video-${item.id}`)
                      if (!isTestMode) {
                        dataLayer.push({
                          event: "clicked-need-it-now-cart",
                        })
                      }
                    }}
                    underline
                    variant="text"
                  >
                    Need it now? Send a Gift Preview Video
                  </Button>
                )}
              </>
            )}
          </ItemInfo>
        </Flex>

        {!outOfStock && (
          <>
            <FlexItemAction>
              {item.view_item_details.length > 0 && (
                <ItemAction
                  aria-controls={`cart-item-details-mobile-${item.id}`}
                  aria-expanded={showDetails}
                  onClick={() => toggleDetails(!showDetails)}
                >
                  Item Details{" "}
                  <FontAwesomeIcon
                    icon={showDetails ? lightAngleDown : lightAngleRight}
                    style={{ verticalAlign: "text-bottom" }}
                  />
                </ItemAction>
              )}
              <ItemAction
                as={Link}
                data-cy={`mobile-cart-modify-btn-${item.style_code}`}
                data-lc="line-items"
                to={item.modify_url}
              >
                Modify Item{" "}
                <FontAwesomeIcon
                  icon={lightAngleRight}
                  style={{ verticalAlign: "text-bottom" }}
                />
              </ItemAction>
            </FlexItemAction>
            <Expand
              id={`cart-item-details-mobile-${item.id}`}
              show={showDetails}
            >
              <Box pb="20px">
                {item.view_item_details.map((detail) => (
                  <React.Fragment key={`mobileItemDetails-${detail.name}`}>
                    {detail.values ? (
                      <>
                        <Detail>{!!detail.label && `${detail.label}: `}</Detail>
                        {detail.values.map((value) => (
                          <SubDetail key={value}>- {value}</SubDetail>
                        ))}
                      </>
                    ) : (
                      <Detail>
                        {!!detail.label && `${detail.label}: `}
                        <span
                          dangerouslySetInnerHTML={{ __html: detail.value }}
                        />
                        {detail.price !== 0 && detail.name === "de" && (
                          <span> ({to_currency(detail.price)})</span>
                        )}
                        {detail.price !== 0 && detail.name !== "de" && (
                          <span> (+{to_currency(detail.price)})</span>
                        )}
                      </Detail>
                    )}
                  </React.Fragment>
                ))}
              </Box>
            </Expand>
            {!item.reject_addons && IS_JEWLR() && <GiftOptions item={item} />}
            {!item.reject_addons &&
              sl.config.enable_extend_warranty &&
              IS_JEWLR() && <WarrantyAddon item={item} />}
          </>
        )}
      </Card>
      {!outOfStock && (
        <LineItemAddon
          addon={item.addons.find((a) => a.addon_type === "BOX_PRINT")}
          item={item}
          itemId={item.id}
        />
      )}
    </>
  )
}

InternalLineItemMobile.propTypes = {
  item: PropTypes.object,
  removeItem: PropTypes.func,
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeItem: (itemId) => {
      dispatch(removeLineItem(itemId, "product"))
    },
  }
}

const LineItemMobile = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalLineItemMobile)

export default LineItemMobile
