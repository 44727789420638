import PropTypes from "prop-types"
import React from "react"

import Row from "components/landing/row"
// import { jewlr_t } from "helpers/application"

export const getDATA = () => ({
  description: {
    desktop:
      "Transform your gift experience with custom packaging and optional add-ons &ndash; perfect for any occasion.",
    mobile:
      "Transform your gift experience with custom <br />packaging and optional add-ons.",
  },
  image: {
    // To update, go to Strapi > Media Library and upload the images. Then get the urls and replace here.
    // Only replace the folder and the file name and keep the domain ("https://imgsmbly.../cms/") unchanged for auto image optimization.
    // desktop: `${sl.config.jewlr_imgsmbly_url}cms/packaging_desktop_e040355753/packaging_desktop_e040355753.jpg`,
    // mobile: `${sl.config.jewlr_imgsmbly_url}cms/packaging_mobile_2385ddf7be/packaging_mobile_2385ddf7be.jpg`,
    desktop: `${sl.config.jewlr_imgsmbly_url}cms/d_valentines_gift_wrap_68c0e6ee03/d_valentines_gift_wrap_68c0e6ee03.jpg`,
    mobile: `${sl.config.jewlr_imgsmbly_url}cms/m_valentines_gift_wrap_879334d588/m_valentines_gift_wrap_879334d588.jpg`,
  },
  link: "/info/packaging",
  title: "Easy Gifting… That’s a Wrap",
})

const LandingPackaging = ({ element, lazyload }) => {
  const DATA = getDATA()

  const data = {
    ...element,
    columns: [
      {
        column_style: {
          flex: { _: 1, desktop: "1 1 40%" },
          ml: { desktop: 5, tablet: 2 },
        },
        components: [
          {
            textarea: {
              style: {
                maxWidth: { tablet: "684px" },
                textAlign: { _: "center", tablet: "left" },
              },
              texts: [
                {
                  style: { mb: { _: 1, tablet: 2 } },
                  text: DATA.title,
                  type: "homepage_heading",
                },
                {
                  style: {
                    display: { tablet: "none" },
                    px: { _: 2 },
                  },
                  text: DATA.description.mobile,
                  type: "homepage_description",
                },
                {
                  style: {
                    display: { _: "none", tablet: "block" },
                    width: { desktop: "428px" },
                  },
                  text: DATA.description.desktop,
                  type: "homepage_description",
                },
                {
                  base_style: "underline",
                  style: {
                    display: "inline-block",
                    fontSize: "18px",
                    fontWeight: "500",
                    letterSpacing: "0.5px",
                    mt: { _: 3, desktop: 4 },
                  },
                  text: "Learn More",
                  type: "link",
                  url: DATA.link,
                },
              ],
            },
          },
        ],
      },
      {
        column_style: {
          flex: { _: 1, desktop: "1 1 60%" },
          order: { tablet: -1 },
        },
        components: [
          {
            image: {
              alt: DATA.title,
              fallback: DATA.image.desktop,
              srcSet: [
                {
                  height: 400,
                  media: "(max-width: 767px)",
                  sizes: "100vw",
                  srcSet: `${DATA.image.mobile}?w=320 320w, ${DATA.image.mobile}?w=360 360w, ${DATA.image.mobile}?w=420 420w, ${DATA.image.mobile}?w=640 640w, ${DATA.image.mobile} 676w`,
                  width: 676,
                },
                {
                  height: 932,
                  media: "(min-width: 768px)",
                  sizes: "(max-width: 1920px) 50vw, 912px",
                  srcSet: `${DATA.image.desktop}?w=320 320w, ${DATA.image.desktop}?w=360 360w, ${DATA.image.desktop}?w=420 420w, ${DATA.image.desktop}?w=640 640w, ${DATA.image.desktop}?w=768 768w, ${DATA.image.desktop}?w=828 828w, ${DATA.image.desktop}?w=1024 1024w, ${DATA.image.desktop}?w=1280 1280w, ${DATA.image.desktop}?w=1440 1440w, ${DATA.image.desktop}?w=1600 1600w, ${DATA.image.desktop} 1612w`,
                  width: 1612,
                },
              ],
              url: DATA.link,
            },
          },
        ],
      },
    ],
    style: {
      alignItems: "center",
      container_style: {
        containWide: true,
        px: { _: 2, desktop: 6 },
        py: { _: 3, tablet: 5 },
        ...element.style,
      },
      flexDirection: { _: "column", tablet: "row" },
      flexWrap: "nowrap",
      justifyContent: "center",
      ...element.container_style,
    },
  }

  return <Row element={data} lazyload={lazyload} />
}

LandingPackaging.propTypes = {
  element: PropTypes.object,
  lazyload: PropTypes.bool,
}

export default LandingPackaging
